define("discourse/plugins/audiograms/discourse/templates/connectors/above-site-header/above-site-header", ["exports", "ember-this-fallback/is-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "@ember/template-factory"], function (_exports, _isComponent, _thisFallbackHelper, _tryLookupHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="above-site-header-test-text" style="background-color: #313030; padding: 3px; text-align: center; color: white">
    <p>This is a test div above the site header. {{foo}}</p>
  </div>
  
  */
  {
    "id": "Qs9P6UVO",
    "block": "[[[10,0],[14,0,\"above-site-header-test-text\"],[14,5,\"background-color: #313030; padding: 3px; text-align: center; color: white\"],[12],[1,\"\\n  \"],[10,2],[12],[1,\"This is a test div above the site header. \"],[41,[28,[32,0],[\"foo\"],null],[[[8,[39,1],null,null,null]],[]],[[[44,[[28,[37,3],null,[[\"foo\"],[[28,[32,1],[\"foo\"],null]]]]],[[[1,[52,[30,1,[\"foo\"]],[28,[30,1,[\"foo\"]],null,null],[28,[32,2],[[30,0],\"foo\",\"[\\\"The `foo` property path was used in the `discourse/plugins/audiograms/discourse/templates/connectors/above-site-header/above-site-header.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.foo}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[1]]]],[]]],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"maybeHelpers\"],false,[\"if\",\"foo\",\"let\",\"hash\"]]",
    "moduleName": "discourse/plugins/audiograms/discourse/templates/connectors/above-site-header/above-site-header.hbs",
    "scope": () => [_isComponent.default, _tryLookupHelper.default, _thisFallbackHelper.default],
    "isStrictMode": false
  });
});