define("discourse/plugins/audiograms/discourse/templates/connectors/extra-nav-item/extra-nav-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <li id="custom-nav-item">
    <a href="https://www.hearingtracker.com" target="_blank" rel="noopener noreferrer">
      <i class="fa fa-external-link"></i> HearingTracker
    </a>
  </li>
  
  */
  {
    "id": "HBp87fm7",
    "block": "[[[10,\"li\"],[14,1,\"custom-nav-item\"],[12],[1,\"\\n  \"],[10,3],[14,6,\"https://www.hearingtracker.com\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-external-link\"],[12],[13],[1,\" HearingTracker\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "discourse/plugins/audiograms/discourse/templates/connectors/extra-nav-item/extra-nav-item.hbs",
    "isStrictMode": false
  });
});